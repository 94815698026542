<template>
    <div class="d-flex align-center primary h-full-vh"  id="forgotpassword-page">
        <div class="session-form-hold">
            <v-card :padding="currentRoute ==='forgot-password'">
                <v-card-text class="text-center pt-0">
                    <div class="d-flex align-center justify-center">
                        <div class="w-60 my-7">
                            <v-img src="@/assets/images/scanspek-logo.png" contain/>
                        </div>
                    </div>
                    <h5>{{currentRoute ==='forgot-password' ? 'Forgot Your Password?' : 'Reset Your Password'}}</h5>
                    <h6 class="text--disabled font-weight-medium">
                        {{currentRoute ==='forgot-password' ? 'We will send passsword recovery link to your registered email.' : 'Reset your password to continue'}}
                    </h6>
                    <v-form @submit.prevent="onSubmit">
                        <v-text-field v-if="currentRoute ==='forgot-password'" ref="email" label="Email Address" v-model="email"
                            class="mb-4" :error-messages="$helpers.errorMsg('email', $v.email, 'Email Address')"
                            required @input="$v.email.$touch()" @blur="$v.email.$touch()">
                        </v-text-field>
                        <v-text-field v-else ref="password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'" name="input-10-2" label="Password"
                            v-model="password" :error-messages="$helpers.errorMsg('name', $v.password, 'Password')"
                            required @input="$v.password.$touch()" @blur="$v.password.$touch()"
                            @click:append="show = !show">
                        </v-text-field>
                        <v-text-field v-if="currentRoute !=='forgot-password'" ref="confirmPassword"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            name="input-10-2"
                            label="Confirm Password"
                            v-model="confirmPassword"
                            :error-messages="$helpers.errorMsg('sameas', $v.confirmPassword, 'Confirm Password')"
                            required
                            @input="$v.confirmPassword.$touch()"
                            @blur="$v.confirmPassword.$touch()"
                            @click:append="show = !show"
                        ></v-text-field>
                        <v-btn class="mb-4 mt-3" block type="submit" color="primary">
                            {{currentRoute ==='forgot-password' ? 'Send Reset Link' : 'Change Password'}}
                        </v-btn>
                    </v-form>
                    <div class="d-flex justify-around flex-wrap" v-if="currentRoute ==='forgot-password'">
                        <v-btn text small color="primary" type="button" @click="$router.push('login')"
                            >Sign in to existing account</v-btn
                        >
                    </div>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { email, required, sameAs } from 'vuelidate/lib/validators';

export default {
    name: 'ForgotPassword',
    metaInfo: {
        title: 'Forgotpassword'
    },
    data() {
        return {
            email: '',
            currentRoute: '',
            password: '',
            confirmPassword: '',
            token: '',
            show: false
        }
    },
    validations() {
        if (this.currentRoute ==='forgot-password') {
            return {
                email: { required, email }
            }
        } else {
            return {
                password: { required },
                confirmPassword: { required, sameAsfield: sameAs('password') }
            }
        }
    },
    created() {
        this.currentRoute = this.$router.currentRoute.path.split('/')[1];
        this.token = this.currentRoute !=='forgot-password' ? this.$router.currentRoute.query.token : '';
    },
    methods: {
        ...mapActions(["sendResetLink", "resetPassword"]),
        onSubmit () {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v, this.$refs);
                return;
            }
            if (this.currentRoute ==='forgot-password') {
                this.sendResetLink({email: this.email}).then((response) => this.$router.push("/login"));
            } else {
                const resetData = {
                    token: this.token,
                    password: this.password
                }
                this.resetPassword(resetData).then((response) => this.$router.push("/login"));
            }
        }
    }
}
</script>

<style scoped>
    .session-form-hold {
        width: 100%;
        max-width: 550px;
        height: 500px;
        margin: 0 auto;
    }
</style>
